import React from 'react';
import CloseSettlement from '../../components/admin/CloseSettlement';
import Layout from '../../templates/layout';
import HasUserRole from '../../templates/hasUserRole';

const closeSettlements = () => {
  return (
    <Layout >
      <HasUserRole role='admin' isPage>
        <CloseSettlement />
      </HasUserRole>
    </Layout>
  );
};

export const Head = () => <title>Zamkniecie rozliczeń | Zapłatomat</title>;

export default closeSettlements;
