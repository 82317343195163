import React, { Component } from 'react';
import {
  Container,
  Divider,
  Button,
  Alert,
  Box,
  TextField,
} from '@mui/material';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions';

function mapStateToProps(state) {
  return {
    closeSettlement: state.closeSettlement,
  };
}

class CloseSettlement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      settlementCode: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.closeSettlement !== this.props.closeSettlement) {
      const { status, reason, data } = this.props.closeSettlement;
      if (status === 'ERROR') {
        this.setState({ error: true, message: reason });
      } else if (data && data.status === 'closed') {
        this.setState({
          error: false,
          message: `Settlement #${data.code} has been closed`,
        });
      }
    }
  }

  handleChange(e) {
    this.setState({
      settlementCode: e.target.value,
      error: false,
      message: '',
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.actions.closeSettlement(this.state.settlementCode);
  };

  isValidCode(code) {
    return /^[abcdefghjkmnpqrstuvwxyz23456789]{10}$/.test(code);
  }

  render() {
    return (
      <Container sx={{ marginTop: '50px' }}>
        <Divider>Zamknięcie rozliczenia</Divider>
        <Box
          component='form'
          sx={{
            '& .MuiTextField-root': { width: '100%' },
            marginTop: '8px',
            marginBottom: '16px',
          }}
          noValidate
          autoComplete='off'
          onSubmit={e => this.handleSubmit(e)}
        >
          <TextField
            sx={{ marginBottom: '12px' }}
            id='settlementCode'
            label='Kod rozliczenia'
            name='settlementCode'
            value={this.state.settlementCode}
            onChange={this.handleChange.bind(this)}
          ></TextField>
          <br />
          <Button
            variant='contained'
            disableElevation
            type='submit'
            disabled={!this.isValidCode(this.state.settlementCode)}
          >
            Zamknij
          </Button>
        </Box>
        {this.state.message && (
          <Alert severity={this.state.error ? 'error' : 'success'}>
            {this.state.message}
          </Alert>
        )}
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(CloseSettlement);
